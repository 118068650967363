<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        <loading-flux :value="loadingPerfil"></loading-flux>
        <div class="row">
            <b-alert
                :show="segundosMensajeActualizacion"
                dismissible
                :variant="mensaje.variant"
                @dismissed="segundosMensajeActualizacion = 0"
                @dismiss-count-down="actualizarSegundosMensajeActualizacion"
            >
                {{ mensaje.texto }}
            </b-alert>
            <div class="col-xl-4">
                <div class="card overflow-hidden">
                    <div class="bg-soft bg-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-3">
                                    <h5 class="text-primary">
                                        Bienvenid@ Nuevamente!
                                    </h5>
                                    <p>Información</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img
                                    src="@/assets/images/profile-img.png"
                                    alt
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <img
                                        :src="urlImagenUsuario(usuario.imagen_perfil)"
                                        alt
                                        class="img-thumbnail rounded-circle"
                                    />
                                </div>
                                <h5 class="font-size-15 text-truncate">
                                    {{ usuario.nombre_usuario }}
                                </h5>
                                <p class="text-muted mb-0 text-truncate">
                                    {{ usuario.nombre_cargo }}
                                </p>
                            </div>

                            <div class="col-sm-8">
                                <div class="pt-4">
                                    <div class="row">
                                        <div class="col-6" v-if="usuario.codigo_rol != 'CLIENTE'">
                                            <h5 class="font-size-15">
                                                {{ proyectos.length }}
                                            </h5>
                                            <p class="text-muted mb-0">
                                                Proyectos
                                            </p>
                                        </div>
                                        <div class="col-6">
                                            <h5 class="font-size-15">
                                                {{ usuario.codigo_usuario }}
                                            </h5>
                                            <p class="text-muted mb-0">
                                                Código Usuario
                                            </p>
                                        </div>
                                        <div
                                            class="mt-4"
                                            v-if="rolModuloPermiso.escritura"
                                        >
                                            <button
                                                class="btn btn-primary btn-sm"
                                                @click="abrirModalEditar()"
                                            >
                                                Modificar Información
                                                <i
                                                    class="mdi mdi-arrow-right ms-1"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card -->

                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Información Personal</h4>

                        <p class="text-muted mb-4">
                            Hola {{ usuario.nombre_usuario }}, detalle de tu
                            Información personal
                        </p>
                        <div class="table-responsive ">
                            <table class="table table-nowrap mb-0">
                                <tbody>
                                    <tr>
                                        <th scope="row">Nombre :</th>
                                        <td>{{ usuario.nombre_usuario }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Código Usuario :</th>
                                        <td>{{ usuario.codigo_usuario }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Fono1 :</th>
                                        <td>{{ usuario.fono1 }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Fono2 :</th>
                                        <td>{{ usuario.fono2 }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail :</th>
                                        <td>{{ usuario.email }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                                            class="mt-4"
                                            v-if="rolModuloPermiso.escritura"
                                        >
                                            <button
                                                class="btn btn-primary btn-sm"
                                                @click="abrirModalContrasena()"
                                            >
                                                Modificar Contraseña
                                                <i
                                                    class="mdi mdi-arrow-right ms-1"
                                                ></i>
                                            </button>
                                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>

            <div class="col-xl-8">
                <div class="row">
                    <div class="col-md-4" v-if="usuario.codigo_rol != 'CLIENTE'">
                        <Stat
                            icon="bx bx-check-circle"
                            title="Proyectos"
                            :value="proyectos.length.toString()"
                        />
                    </div>
                    <div class="col-md-4">
                        <Stat
                            icon="bx bx-user-circle"
                            title="Rol"
                            :value="usuario.nombre_rol"
                        />
                    </div>
                </div>
                <!-- <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Revenue</h4>
            <div id="revenue-chart" class="apex-charts"></div>
            <apexchart
              class="apex-charts"
              type="bar"
              height="320"
              :series="revenueChart.series"
              :options="revenueChart.chartOptions"
            ></apexchart>
          </div> 
        </div>-->

                <div class="card" v-if="usuario.codigo_rol != 'CLIENTE'">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Resumen Mis Proyectos</h4>
                        <div class="table-responsive" style="height:498px">
                            <table
                                class="table sticky-header table-nowrap table-hover mb-0"
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Código Proyecto</th>
                                        <th scope="col">Nombre Proyecto</th>
                                        <th scope="col">Etapa</th>
                                        <th scope="col">Fecha Creación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(proyecto, i) in proyectos"
                                        :key="i"
                                    >
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ proyecto.codigo_proyecto }}</td>
                                        <td>{{ proyecto.nombre_proyecto }}</td>
                                        <td>
                                            {{ proyecto.nombre_etapa_proyecto }}
                                        </td>
                                        <td>
                                            {{
                                                formatDate(
                                                    proyecto.fecha_creacion
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <b-modal
            v-model="mostrarModal"
            title="Agregar Usuario"
            hide-footer
            size="lg"
        >
            <loading-flux :value="loading"></loading-flux>
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreUsuario">Nombre Usuario</label>
                            <input
                                id="nombreUsuario"
                                v-model="$v.form.nombreUsuario.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre Usuario"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.nombreUsuario.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.nombreUsuario.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3 row">
                            <label for="rutUsuario">Rut</label>
                            <div class="col-10">
                                <input
                                    id="rutUsuario"
                                    v-model="$v.form.rutUsuario.$model"
                                    type="number"
                                    class="form-control"
                                    placeholder="Rut"
                                    v-on:input="
                                        calcularDvRutUsuario(
                                            $v.form.rutUsuario.$model
                                        )
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutUsuario.$error,
                                    }"
                                />
                            </div>
                            <div class="col-2">
                                <input
                                    id="rutUsuarioDv"
                                    v-model="$v.form.rutUsuarioDv.$model"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutUsuarioDv.$error,
                                    }"
                                />
                            </div>
                            <div
                                v-if="submitted && !$v.form.rutUsuario.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3 row">
                            <label for="rutEmpresa">Rut Empresa</label>
                            <div class="col-10">
                                <input
                                    id="rutEmpresa"
                                    v-model="$v.form.rutEmpresa.$model"
                                    type="number"
                                    class="form-control"
                                    placeholder="Rut"
                                    v-on:input="
                                        calcularDvRutEmpresa(
                                            $v.form.rutEmpresa.$model
                                        )
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutEmpresa.$error,
                                    }"
                                />
                            </div>

                            <div class="col-2">
                                <input
                                    id="rutEmpresaDv"
                                    v-model="$v.form.rutEmpresaDv.$model"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutEmpresaDv.$error,
                                    }"
                                />
                            </div>

                            <div
                                v-if="submitted && !$v.form.rutEmpresa.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreEmpresa">Nombre empresa</label>
                            <input
                                id="nombreEmpresa"
                                v-model="$v.form.nombreEmpresa.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre empresa"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.nombreEmpresa.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.nombreEmpresa.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fono1">Fono 1</label>
                            <input
                                id="fono1"
                                v-model="$v.form.fono1.$model"
                                type="text"
                                class="form-control"
                                placeholder="Fono 1"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.fono1.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.fono1.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fono2">Fono2</label>
                            <input
                                id="fono2"
                                v-model="$v.form.fono2.$model"
                                type="text"
                                class="form-control"
                                placeholder="Fono2"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.fono2.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.fono2.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="imagenPerfil">Imagen Perfil</label>
                            <input
                                class="form-control"
                                type="file"
                                name="imagenPerfil"
                                accept=".png, .jpg, .jpeg,.bmp"
                                @change="onFileChange($event)"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button
                        v-if="rolModuloPermiso.escritura"
                        type="submit"
                        variant="success"
                        class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="mostrarModalContrasena"
            title="Agregar Usuario"
            hide-footer
            size="md"
        >
            <loading-flux :value="loadingContrasena"></loading-flux>
             <form @submit.prevent="modificarContrasena">
                                <div class="mb-3">
                                    <label for="nuevacontrasena"
                                        >Nueva Contraseña</label
                                    >
                                    <input
                                        type="text"
                                        v-model="$v.formContrasena.nuevaContrasena.$model"
                                        class="form-control"
                                        id="nuevacontrasena"
                                        placeholder="Contraseña"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.formContrasena.nuevaContrasena.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.formContrasena.nuevaContrasena.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.formContrasena.nuevaContrasena
                                                    .required
                                            "
                                            >Contraseña Requerida.</span
                                        >
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="repetirContrasena"
                                        >Repetir Contraseña</label
                                    >
                                    <input
                                        type="text"
                                        v-model="$v.formContrasena.repetirContrasena.$model"
                                        class="form-control"
                                        id="repetirContrasena"
                                        placeholder="Contraseña"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                $v.formContrasena.repetirContrasena
                                                    .$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                                $v.formContrasena.repetirContrasena.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !$v.formContrasena.repetirContrasena
                                                    .required
                                            "
                                            >Contraseña Requerida.</span
                                        >
                                        <span
                                            v-if="
                                                !$v.formContrasena.repetirContrasena
                                                    .sameAsPassword
                                            "
                                            >La contraseña no coincide.</span
                                        >
                                    </div>
                                </div>
                                <div class="mb-3 row mb-0">
                                    <div class="col-12 text-end">
                                        <button
                                            class="btn btn-primary w-md"
                                            type="submit"
                                        >
                                            Cambiar
                                        </button>
                                    </div>
                                </div>
                            </form>
        </b-modal>
    </v-container>
</template>
<script>
// import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import { revenueChart } from "./data-profile";
import Stat from "@/components/widgets/stat";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { usuarioMethods, usuarioComputed } from "@/state/helpers";
import moment from "moment";
moment.locale("es");
import { required, numeric,sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    page: {
        title: "Perfil",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { 
        // Layout,
        PageHeader,
        Stat,
    },
    data() {
        return {
            loading: false,
            loadingPerfil:false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            // revenueChart: revenueChart,
            title: "Perfil",
            items: [
                {
                    text: "Usuarios",
                },
                {
                    text: "Perfil",
                    active: true,
                },
            ],
            proyectos: [],
            proyectosCargando: false,
            usuarioSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            usuarios: [],
            usuariosCargando: false,
            roles: [],
            rolesCargando: false,
            form: {
                nombreUsuario: null,
                rutUsuario: null,
                rutUsuarioDv: null,
                rutEmpresa: null,
                rutEmpresaDv: null,
                nombreEmpresa: null,
                email: null,
                fono1: null,
                fono2: null,
            },
            imagenPerfil: null,
            tipoUsuarios: [
                {
                    tipo_usuario_id: "FLUXSOLAR",
                    nombre_tipo_usuario: "FLUXSOLAR",
                },
                { tipo_usuario_id: "CLIENTE", nombre_tipo_usuario: "CLIENTE" },
                { tipo_usuario_id: "VISITA", nombre_tipo_usuario: "VISITA" },
            ],
            tipoUsuariosCargando: false,
            cargos: [],
            cargosCargando: false,
            usuario: {},
            rolModuloPermiso: {},
            mostrarModalContrasena: false,
            loadingContrasena:false,
            isResetError:false,
            formContrasena: {
                nuevaContrasena: "",
                repetirContrasena: "",
            },
        };
    },
    validations: {
        form: {
            nombreUsuario: { required },
            rutUsuario: { numeric },
            rutUsuarioDv: {},
            rutEmpresa: { numeric },
            rutEmpresaDv: {},
            nombreEmpresa: {},
            email: {},
            fono1: {},
            fono2: {},
        },
        formContrasena: {
            nuevaContrasena: {
                required,
            },
            repetirContrasena: {
                required,
                sameAsPassword: sameAs("nuevaContrasena"),
            },
        },
    },
    mounted() {
        this.obtenerUsuarioLocal();
        this.obtenerProyectosUsuarioLocal();
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...proyectoMethods,
        ...usuarioMethods,
        ...authMethods,
        urlImagenUsuario(imagen_perfil){
            return `${process.env.VUE_APP_URL_IMAGENES_USUARIOS}${imagen_perfil}`
        },
        onFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();
                this.imagenPerfil = file;
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalEditar() {
            this.resetForm();
            this.$v.form.nombreUsuario.$model = this.usuario.nombre_usuario;
            this.$v.form.rutUsuario.$model = this.usuario.rut_usuario;
            this.$v.form.rutUsuarioDv.$model = this.usuario.rut_usuario_dv;
            this.$v.form.rutEmpresa.$model = this.usuario.rut_empresa;
            this.$v.form.rutEmpresaDv.$model = this.usuario.rut_empresa_dv;
            this.$v.form.nombreEmpresa.$model = this.usuario.nombre_empresa;
            this.$v.form.email.$model = this.usuario.email;
            this.$v.form.fono1.$model = this.usuario.fono1;
            this.$v.form.fono2.$model = this.usuario.fono2;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        submit(e) {
            this.loading = true;
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }

            let id = this.usuario.id;

            const nombre_usuario = this.$v.form.nombreUsuario.$model;
            const rut_usuario = parseInt(this.$v.form.rutUsuario.$model);
            const rut_usuario_dv = this.$v.form.rutUsuarioDv.$model;
            const rut_empresa = this.$v.form.rutEmpresa.$model;
            const rut_empresa_dv = this.$v.form.rutEmpresaDv.$model;
            const nombre_empresa = this.$v.form.nombreEmpresa.$model;
            const email = this.usuario.email;
            const token_usuario = this.usuario.token_usuario;
            const fono1 = this.$v.form.fono1.$model;
            const fono2 = this.$v.form.fono2.$model;

            let formData = new FormData();
            formData.append("file", this.imagenPerfil);

            formData.append(
                "usuario",
                JSON.stringify({
                     id,
                token_usuario,
                nombre_usuario,
                rut_usuario,
                rut_usuario_dv,
                rut_empresa,
                rut_empresa_dv,
                nombre_empresa,
                email,
                fono1,
                fono2,
                })
            );

            this.actulizarUsuarioPerfil(formData)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == null) {
                        this.mensaje.variant = "warning";
                        this.mensaje.texto =
                            "No hay cambios en el perfil !!!";
                        this.mostrarModal = false;
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Usuario actualizado correctamente!!!";
                        this.mostrarModal = false;
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerUsuarioLocal();
                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });

            this.submitted = false;
        },
        abrirModalContrasena(){
            this.resetForm();
            this.mostrarModalContrasena = true
        },
        modificarContrasena() {
            this.loadingContrasena = true;
            this.submitted = true;
 
            this.$v.formContrasena.$touch();
            if (this.$v.formContrasena.$invalid) {
                this.loadingContrasena = false;
                return false;
            }
            this.cambiarContrasena({
                clave: this.$v.formContrasena.nuevaContrasena.$model,
                token_usuario: this.user.token_usuario,
            })
                .then((res) => {
                    if (res.status == 200) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Contraseña modificada correctamente!!!";
                        
                    }
                    this.loadingContrasena = false;
                    this.isResetError = false;
                    this.mostrarModalContrasena = false
                    this.segundosMensajeActualizacion = this.segundos;
                })
                .catch((error) => {
                    this.loadingContrasena = false;
                    this.error = error ? error : "";
                    this.isResetError = true;
                });
        },
        resetForm() {
            this.form = {
                nombreUsuario: null,
                rutUsuario: null,
                rutUsuarioDv: null,
                rutEmpresa: null,
                rutEmpresaDv: null,
                nombreEmpresa: null,
                email: null,
                fono1: null,
                fono1: null,
            };

            this.formContrasena = {
                nuevaContrasena: null,
                repetirContrasena: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        obtenerProyectosUsuarioLocal() {
            this.proyectosCargando = true;
            this.obtenerProyectosUsuario(this.user.email)
                .then((res) => {
                    this.proyectos = res.body;
                    this.proyectosCargando = false;
                })
                .catch((error) => {
                    this.proyectosCargando = false;
                });
        },
        obtenerUsuarioLocal() {
            this.loadingPerfil = true
            this.obtenerUsuarioTokenUsuario(this.user.token_usuario)
                .then((res) => {
                    this.usuario = res.body;
                    this.loadingPerfil = false
                })
                .catch((error) => {this.loadingPerfil = false});
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
    },
    computed: {
        ...authUsuarioComputed,
    },
};
</script>
